import { VERSION } from './version';

export const environment = {
  production: false,
  name: 'test',
  version: VERSION,
  hideWorkInProgress: true,
  apiBaseUrl: 'https://medis.azureedge.net/server/',
  localBaseUrl: 'https://medis.azureedge.net/',
  supportEmail: 'support@madjenta.com',
  keepAliveInterval: 30000,
  syncRemoteName: 'prod',
  syncRemoteHost: 'https://enqmed.com/',
  disableEmailSent: false,
  systemWarning: '',
};
